<template>
  <div :class="['asf-login-form', { 'm-general-error': errors?.__general__ }]">
    <AsfForm id="login-form" :error="errors" v-bind="form" @form:submitted="onSubmit" @click="handleClick">
      <template #form-buttons>
        <AsfButton type="submit" class="asf-button-primary" v-e2e="'login-button'">
          <slot name="submit-button-content">
            {{ $t('loginPage.form.signIn') }}
          </slot>
        </AsfButton>
      </template>
      <template #password-slot-before>
        <AsfButton
          type="button"
          class="asf-link asf-link-primary absolute top-0 right-0 text-sm z-1"
          @click="toggleForgotPassModal"
        >
          {{ $t('loginPage.heading.forgotPassword') }}
        </AsfButton>
      </template>
    </AsfForm>
    <LazyHydrationWrapper when-idle>
      <AsfModal
        v-if="isForgotPassModalOpened"
        role="dialog"
        :visible="isForgotPassModalOpened"
        use-focus-trap
        @close="toggleForgotPassModal"
      >
        <ForgotPassword :is-from-login-page="true" @submit:success="toggleForgotPassModal" />
      </AsfModal>
    </LazyHydrationWrapper>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, SetupContext } from 'vue'
import { AsfFormProps } from '@ui/types'

type LoginFormProps = {
  form: AsfFormProps
  errors?: AsfFormProps['error']
}

export default defineComponent({
  name: 'LoginForm',
  emits: ['form:submitted'],
  props: {
    form: { type: Object as PropType<LoginFormProps['form']>, required: true },
    errors: { type: Object as PropType<LoginFormProps['errors']>, default: () => ({}) }
  },
  setup(props: LoginFormProps, { emit }: SetupContext) {
    const isForgotPassModalOpened = ref(false)

    const toggleForgotPassModal = () => (isForgotPassModalOpened.value = !isForgotPassModalOpened.value)

    const formModels = computed(() => props.form.formModel)
    const storedLoginEmail = getLocalStorageItem('loginEmail')

    if (storedLoginEmail) formModels.value.email = storedLoginEmail

    const onSubmit = (model: Record<string, any>) => {
      if (formModels.value.rememberMe && formModels.value.email) {
        setLocalStorageItem('loginEmail', formModels.value.email as string)
      }

      emit('form:submitted', model)
    }

    // TODO: i.khartov - hack for - https://jira.ontrq.com/browse/AGSF-4188, refactor this
    const handleClick = (event: Event) => {
      // @ts-ignore
      if (!window.chrome) {
        return
      }

      const target = event.target as HTMLInputElement

      if (target.tagName === 'INPUT') {
        const value = target.value
        target.value = ''
        target.value = value
      }
    }

    return { isForgotPassModalOpened, handleClick, onSubmit, toggleForgotPassModal }
  },
  head: {}
})
</script>
